<template>
  <div class="pa-4 details-container" :class="{ 'is-editable': isEditable, 'is-dense': isDense }">
    <template v-if="isEditable">
      <div class="d-flex justify-space-between" :class="{ 'align-center': isDense }">
        <div class="flex-1">
          <div class="header">{{ header }}</div>
          <slot></slot>
        </div>
        <v-btn icon @click="$emit('click', fieldName)"><v-icon>mdi-pencil</v-icon></v-btn>
      </div>
    </template>
    <template v-else>
      <div class="header">{{ header }}</div>
      <slot></slot>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      required: true
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: false
    },
    fieldName: {
      type: String,
      required: false,
      default: ''
    },
    isDense: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
.details-container {
  border-radius: 4px;
  padding: 16px;
  background-color: $color-neutral-0;
  margin-bottom: 16px;

  &.is-dense {
    .header {
      margin-bottom: 8px;
    }
  }
}

.header {
  color: $color-text-secondary;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  margin-bottom: 16px;
}
</style>
