<template>
  <div>
    <div class="appointment-details">
      <status-manager
        class="mb-4"
        :read-only="readOnly"
        :appointment="appointment"
        :warehouse-triggers="warehouseTriggers"
        :should-edit-status="shouldEditStatus"></status-manager>

      <section class="sidebar-content">
        <div class="sidebar">
          <details-content-box header="Carrier Contact">
            <div>{{ appointment.user.firstName }} {{ appointment.user.lastName }}</div>
            <div>{{ appointment.user.email }}</div>
            <div>{{ appointment.user.phone }}</div>
            <outline-button small inline class="mt-4">Change Carrier</outline-button>
          </details-content-box>
          <details-content-box header="Appointment Info" class="mb-0 full-height">
            <div class="data">
              <div class="data-group">
                <span class="data-header">Dock</span>
                <span>{{ appointment.dock.name }}</span>
              </div>
              <div class="data-group action-group">
                <div class="d-flex flex-column">
                  <span class="data-header">Load Type</span>
                  <span>{{ appointment.loadType.name }}</span>
                </div>
                <v-btn icon><v-icon>mdi-pencil</v-icon></v-btn>
              </div>
              <div class="data-group">
                <span class="data-header">Warehouse</span>
                <span>{{ appointment.dock.warehouse.name }}</span>
              </div>
              <div class="data-group action-group">
                <div class="d-flex flex-column">
                  <span class="data-header">Confirmation Number</span>
                  <span>{{ appointment.confirmationNumber }}</span>
                </div>
                <copy-content
                  :content="appointment.confirmationNumber"
                  label="Click to copy confirmation number"></copy-content>
              </div>
              <div class="data-group action-group">
                <div class="d-flex flex-column">
                  <span class="data-header">Appointment ID</span>
                  <span>{{ appointment.id }}</span>
                </div>
                <copy-content
                  :content="appointment.id"
                  label="Click to copy confirmation number"></copy-content>
              </div>
            </div>
          </details-content-box>
        </div>
        <div class="content flex-1">
          <details-content-box header="Appointment tags" is-dense>
            <tag-manager
              outlined
              v-model="tags"
              :outlined-chips="true"
              small-chips
              :should-enable-custom-tags="true"
              :read-only="!$rolePermissions.canUpdateAppointmentTags || readOnly"
              placeholder="Add tags"
              hide-instructions
              :allowTagDelete="!lockTagEditing" />
          </details-content-box>

          <details-content-box
            :header="$refNumSettings(appointment.dock.warehouse).displayName"
            is-editable
            :field-name="EDITABLE_FIELDS.refNumber"
            is-dense
            @click="handleEditClick">
            {{ appointment.refNumber || '&#8212;' }}
          </details-content-box>

          <details-content-box
            header="Notes"
            is-editable
            is-dense
            :field-name="EDITABLE_FIELDS.notes"
            @click="handleEditClick">
            <div class="html-content" v-html="appointment.notes || '&#8212;'"></div>
          </details-content-box>

          <details-content-box
            header="Booking details"
            :is-dense="Object.keys(customFields).length === 0"
            is-editable
            :field-name="EDITABLE_FIELDS.customFields"
            @click="handleEditClick">
            <span v-if="!Object.keys(customFields).length">&#8212;</span>
            <div class="data" v-else>
              <div
                class="data-group no-divider"
                v-for="customField in Object.values(customFields)"
                :key="customField.key">
                <span class="data-header">{{ customField.label }}</span>
                <span v-html="getCustomFieldValue(customField)"></span>
              </div>
            </div>
          </details-content-box>

          <details-content-box header="Email subscribers" is-dense class="mb-0">
            <email-list-field outlined small-chips v-model="ccEmails" ref="emailFieldList" />
          </details-content-box>
        </div>
      </section>

      <!--    <custom-forms-flow-dialog-->
      <!--      :external-activator="true"-->
      <!--      :warehouse="warehouse"-->
      <!--      :show-dialog="hasActiveTrigger"-->
      <!--      :object-id="appointment.id"-->
      <!--      :trigger="activeTrigger"-->
      <!--      @close="cleanUpCustomFormsData"-->
      <!--      @update="updateCustomFormsData"-->
      <!--      @create="value => createBulkCustomFormsData(value, warehouse.id)"></custom-forms-flow-dialog>-->
    </div>
  </div>
</template>

<script>
import AppointmentDetailsBase from '@/modules/appointments/components/details/AppointmentDetailsBase.vue';
import DetailsContentBox from '@/modules/appointments/components/details/DetailsContentBox.vue';
import { ref, watch } from 'vue';
import { isEqual } from 'lodash';

export default {
  components: { DetailsContentBox },
  extends: AppointmentDetailsBase,
  setup(props) {
    const EDITABLE_FIELDS = {
      refNumber: 'refNumber',
      notes: 'notes',
      customFields: 'customFields'
    };
    function handleEditClick(fieldName) {
      console.log('Edit clicked for field:', fieldName);
    }

    const ccEmails = ref(props.appointment.ccEmails);

    async function updateCCEmails(oldVal) {
      try {
        await axios.patch(`appointment/${props.appointment.id}`, {
          ccEmails: ccEmails.value
        });
      } catch (e) {
        ccEmails.value = oldVal;
      }
    }

    watch(ccEmails, async (newVal, oldVal) => {
      if (isEqual(newVal, oldVal) || isEqual(props.appointment.ccEmails, newVal)) return;
      await updateCCEmails(oldVal);
    });

    return {
      EDITABLE_FIELDS,
      handleEditClick,
      ccEmails
    };
  }
};
</script>

<style scoped lang="scss">
.appointment-details {
  background-color: $color-background-transparency-inverted;
}

.sidebar-content {
  display: flex;
  gap: 16px;
}

.sidebar {
  width: 317px;
  max-width: 100%;
}

::v-deep .html-content p:last-child {
  margin-bottom: 0;
}

::v-deep .data-group {
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  gap: 4px;

  &.action-group {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    i {
      font-size: 20px !important;
    }
  }

  &:not(:first-child):not(.no-divider) {
    border-top: 1px solid $color-line-divider;
    padding-top: 12px;
  }

  .data-header {
    font-size: 14px;
    font-weight: 600;
    color: $color-text-tertiary;
  }
}
</style>
