<template>
  <div class="full-height">
    <section class="sidebar-content">
      <div class="sidebar">
        <v-tabs v-model="tab" vertical :centered="false" hide-slider>
          <v-tab key="status">Status & Details</v-tab>
          <v-tab key="claims_support" :disabled="claimSupportTriggers.length === 0">
            Claims Support
          </v-tab>
          <v-tab key="gate_management">Gate Management</v-tab>
          <v-tab key="drivers_chat">Drivers Chat</v-tab>
          <v-tab key="audit_log">Audit Log</v-tab>
        </v-tabs>
      </div>
      <div class="content">
        <v-tabs-items v-model="tab">
          <v-tab-item key="details" :transition="false">
            <appointment-details-status v-if="tab === 0" v-bind="$props" />
          </v-tab-item>
          <v-tab-item key="claims_support" :transition="false">
            <appointment-details-claims v-if="tab === 1" v-bind="$props" />
          </v-tab-item>
          <v-tab-item key="gate_management" :transition="false"></v-tab-item>
          <v-tab-item key="drivers_chat" :transition="false"></v-tab-item>
          <v-tab-item key="audit_log" :transition="false" @click="trackApptDetailsMixpanelEvent">
            <appointment-details-audit-log v-if="tab === 4" v-bind="$props" />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </section>

    <custom-forms-flow-dialog
      :external-activator="true"
      :warehouse="warehouse"
      :show-dialog="hasActiveTrigger"
      :object-id="appointment.id"
      :trigger="activeTrigger"
      @close="cleanUpCustomFormsData"
      @update="updateCustomFormsData"
      @create="value => createBulkCustomFormsData(value, warehouse.id)"></custom-forms-flow-dialog>
  </div>
</template>

<script>
import AppointmentDetailsBase from '@/modules/appointments/components/details/AppointmentDetailsBase.vue';
import { ref, watch } from 'vue';

/**
 * @displayName Appointment Details
 */
export default {
  extends: AppointmentDetailsBase,
  emits: ['change-active-tab'],
  setup(props, context) {
    const tab = ref(0);

    watch(tab, () => {
      context.emit('change-active-tab', tab);
    });

    return {
      tab
    };
  },
  destroyed() {
    this.$eventHub.$off('action-field-submitted', this.handleActionFieldTriggered);
  },
  beforeMount() {
    this.$eventHub.$on('action-field-submitted', this.handleActionFieldTriggered);
    this.trackApptDetailsMixpanelEvent();
  }
};
</script>

<style scoped lang="scss">
.sidebar-content {
  display: flex;
}

.sidebar {
  border-right: 1px solid $color-line-divider;
}

.content {
  padding: 24px;
  flex: 1;
  background-color: $color-background-transparency-inverted;
}

.v-tabs {
  position: sticky;
  top: 0;
}

.v-tab {
  font-size: 12px;
  height: auto;
  padding: 16px 12px;
  border-bottom: 2px solid $color-line-divider;
  font-weight: 600;

  &--active {
    color: $color-primary-100;
    border-bottom: 2px solid $color-primary-60;
  }
}
</style>
